import React, { Fragment } from 'react';
import { FormItemTypes, FormValues, PageBreakItem } from "./data/form";
import ResultsValue from './ResultsValue';
import Calculations from './Calculation';
import data from './data/DOAForm';
import PrintPageBreak from './components/PrintPageBreak';
import EmptyLine from './components/EmptyLine';
import Complete from './Complete';

function getDataItem(id:string) {
  return data.find((x) => x.id === id) ?? {} as FormItemTypes;
}

const mustHold = [
  'Oral Hypoglycemics',
  'Diuretics',
  'ACE-inhibitors',
  'Angiotensin II Receptor Blockers (ARBs)',
  'NSAIDS',
  'Anti-gout medications',
  'Erectile Dysfunction medications',
  'CNS stimulants',
  'Vitamins/Herbals - hold 3 weeks preop'
];
const mustTake = [
  'Beta blockers',
  'Inhalers',
  'PPIs and H2 blockers',
  'Parkinson’s medications',
  'Anti-seizure medications',
  'Psychiatric medications',
  'Chronic pain medications',
  'Methadone',
];

export default class ResultsArray extends React.Component<{flat:FormValues,pageHistory:number[],showField:(id:string) => boolean}> {
  render() {
    const {flat,pageHistory,showField} = this.props;
    const dob = flat["question1-dob"] as string;

    let lastWasBreak = true;
    let curPageNumber = -1;
    let includePage = true;
    // @ts-ignore
    let curPage:PageBreakItem = {};
    let healthy = true;
    let beforePhysicalExam = true;
    const showPrepGeneral = showField('paragraphPrepGeneral');
    const showPrepAnesthi = showField('paragraphAnesthesiology');
    const unoptimized = Object.keys(flat).filter(id => id.startsWith('question130') && !id.startsWith('question130-other') && !id.endsWith("-Details"))
    const unoptimizedOther = flat["question130-other"];
    const unoptimizedOtherDetails = flat["question130-other-Details"];
    const preadmission = showPrepGeneral || showPrepAnesthi;
    const hold = typeof flat["questionMustHold"] === "string" ? flat["questionMustHold"].trim() : undefined;
    const showglp1 = showField('questionMustHoldSpecificGLP1');
    const holdglp1 = showglp1 && flat["questionMustHoldSpecificGLP1-glp1"]?.length ? flat["questionMustHoldSpecificGLP1-glp1"] + " for 3 weeks" : undefined;
    const showsglt2 = showField('questionMustHoldSpecificSGLT2');
    const holdsglt2 = showsglt2 && flat["questionMustHoldSpecificSGLT2-sglt2"]?.length ? flat["questionMustHoldSpecificSGLT2-sglt2"] + " for 3 days" : undefined;
    const holdEmpty = !hold?.trim() || hold === "None";
    const showHoldNone = !holdEmpty || (!holdglp1 && !holdsglt2);
    const take = typeof flat["questionMustTake"] === "string" ? flat["questionMustTake"].trim() : undefined;
    const printLines=(count:number) => {
      const arr = [];
      for (let i = 0; i < count; i++) {
        arr.push(<EmptyLine key={i}/>);
      }
      return arr;
    }
    return (
      <div>
        <div className="ResultsHeader">
          <div>
            <div className="PatientProfile">
              <div>
                {/* @ts-ignore */}
                <b>{Calculations.getAge(dob)}</b> yr old <b>{flat['qGender']}</b> for <b>{flat["question3-surgical_procedure"]}</b> with <b>{flat['question3-surgeon']}</b> on <b>{flat['question4-procedureDate']?.replaceAll('-', '/')}</b> at <b>{flat['questionSurgerySite']}</b>
              </div>
            </div>
          </div>
        </div>

        <div className="MedicalProfile">
          <h2>MEDICAL PROFILE SCREENING</h2>
          {data.map((item) => {
            const value = flat[item.id];
            let showValue = value != null;
            // Custom renderings
            // NOTE: ensure healthy=false and lastWasBreak=false is set
            if (item.id === "question26" && flat['question26'] !== 'Non-Smoker') {
              healthy = false;
              lastWasBreak = false;
              return <div key={item.id} id={item.id}>
              <div style={{display: "flex"}}>
                <b>{flat['question26']}</b>
                <div className="ResultsSeparator" />
                <div>{flat['question27a-packyrs']}</div>
                {flat['question26'] === 'Ex-Smoker' && (
                  <Fragment key={item.id}>
                    <div className="ResultsSeparator" />
                    <div>Quit:  {flat['question27b-exyears']}</div>
                  </Fragment>
                )}
              </div>
            </div>
            }
            if (item.id === "questionEjectionFraction" && flat['question61']?.includes("Yearly Echocardiograms")) {
              healthy = false;
              lastWasBreak = false;
              return <div key={item.id}>
                <div className='FieldItemResult'>Patient has yearly echocardiograms done</div>
                <ResultsValue item={item} flat={flat} />
              </div>
            }
            if (item.id === "functionalAssessment" && item.type === "BOOLEAN_GRID") {
              // @ts-ignore
              const arr:string[] = item.rows.map((row) => flat[item.id + "-" + row.key||row])
              // @ts-ignore
              const total = arr.reduce((total, val) => total + (val === "Yes"), 0)
              if (total > 2) return null;
              healthy = false;
              lastWasBreak = false;
              return <div key ={item.id}>
                <div>
                  M-DASI-4Q <b>Function Assessment {total}/{item.rows.length}</b>, 50% or less chance of achieving 4 METS of activity (approximate)
                </div>
                <div>
                  Etiology details: {flat[item.id+'-Details']}
                </div>
              </div>
            }

            // Generic renderings
            switch (item.type) {
              case 'BOOLEAN':
              case 'BOOLEAN_DETAILS':
                // @ts-ignore
                const rTitle = item.resultTitle?.[value];
                if (rTitle === "") showValue = false;
                if (item.detailsOn) {
                  if (value !== item.detailsOn) showValue = false;
                } else {
                  if (!rTitle && value === "False") showValue = false;
                }
                break;
              case 'RADIO':
                const option = item.options.find(o => {
                  return typeof o === "object" && ((o.value ?? o.title) === value)
                });
                // @ts-ignore
                if (option?.hideResult) {
                  return null;
                }
                break;
              case 'CHECKBOX':
                showValue = !!value?.length
                break;
              case 'FIELDS':
                showValue = true;
                break;
              // case 'PAGE_BREAK':
              // case 'SECTION_HEADER':
              case 'PARAGRAPH':
                return null;
            }
            if (item.type === "PAGE_BREAK") {
              curPageNumber++;
              curPage = item;
              includePage = pageHistory.includes(curPageNumber);
              if (item.id === 'pagePhysicalExam') {
                beforePhysicalExam = false;
                if (healthy) {
                  return <div key="_healthy" className="Healthy">HEALTHY</div>
                }
              }
            } else if (item.type === 'SECTION_HEADER') {
              if (!curPage.hideResult && !lastWasBreak && !item.hideResult) {
                lastWasBreak = true;
                return <br key={item.id} id={item.id} />
              }
            } else if (
              (includePage && (item.hideResult === false || !(item.hideResult || curPage.hideResult)))
              && showValue
            ) {
              if (beforePhysicalExam) {
                healthy = false;
              }
              lastWasBreak = false;
              const detailsValue = flat[item.id + '-Details'];
              return <div key={item.id} id={item.id}>
                <div style={{display: "flex"}}>
                  <ResultsValue item={item} flat={flat} />
                  {detailsValue && (
                    <>
                      <div className='ResultsSeparator' />
                      <div style={{fontWeight: item.boldDetails ? 700 : 500}}>{detailsValue}</div>
                    </>
                  )}
                </div>
              </div>
            }
            return null;
          })}
        </div>

        {(!!unoptimized.length || !!unoptimizedOther?.length || !!unoptimizedOtherDetails) && (
          <>
          <h2><u>Recommended Interventions for Assessor</u></h2>
          {unoptimized.map(id => {
            const value = flat[id];
            const valueDetails = flat[id+'-Details'];
            if (!value) return null;
            return <div id={id} key={id} style={{display: "flex"}}>
              {value} {!!valueDetails && (<>
                <div className="ResultsSeparator" />
                <div>{valueDetails}</div>
              </>)}
            </div>;
          })}
          {(!!unoptimizedOther?.length || !!unoptimizedOtherDetails) && (
            <div>Other: {flat['question130-other-Other']} - {flat['question130-other-Details']}</div>
          )}
        </>
        )}

        {(flat["question131a"] === "True" || preadmission) && (
          <div>
            <h2>Consults/Subspecialty Review Recommended</h2>
            {showPrepGeneral &&
              <div className='FieldItemResult'><b>General Internal Medicine: PAC physician</b></div>}
            {showPrepAnesthi && 
              <div className='FieldItemResult'><b>Anesthesiology: PAC physician</b></div>}
            {(showPrepAnesthi || showPrepGeneral) && <br/>}
            <ResultsValue item={getDataItem('questions131b')} flat={this.props.flat} />
          </div>
        )}

        {!!flat['questionAssessorComments'] && (
          <div className="avoidbreak AssessorComments">
            <h2>General Comments from the Assessor</h2>
            <pre>{flat['questionAssessorComments']}</pre>
          </div>
        )}
        
        <div className="avoidbreak ContactInformation">
          <h2>Health Care Provider</h2>
          <div>
            <div className='FieldItemResult'> {flat['question136'] === "Other" ? flat['question136-Other'] : flat['question136']}</div>
          </div>
          <div className="SignatureLine">
            <ResultsValue item={getDataItem('question138')}  flat={this.props.flat} />
            <div className="onlyprint">
              <div className="ProviderSignature">
                <div>Signature x:</div>
                <div></div>
              </div>
              <div className="ProviderDate">
                <div>Date:</div>
                <div>{flat["question5-todaysDate"]}</div>
              </div>
            </div>
          </div>
        </div>

        <PrintPageBreak className={preadmission ? 'hide' : 'onlyprint'}/>

        <div className={preadmission ? 'hide' : 'onlyprint'}>
          <div className="PatientInstructionsTitle">
            <h2>Instructions for Patient</h2>
            <div className="Logo">
              <img src="/logos/logo.png" alt="Sask Health Authority logo" />
            </div>
            <div className="Logo">
              <img src="/logos/uofs-logo.png" alt="U of S logo" />
            </div>
          </div>
        <div className='columns'>
          <div className='MedicationResults'>
            <h4><b>MUST HOLD</b> morning of surgery:</h4>
            {mustHold.map((text, index) => <li key={index}>{text}</li>)}
          </div>
          <div className='MedicationResults'>
            <h4><b>MUST TAKE</b> morning of surgery (with sip of water):</h4>
            {mustTake.map((text, index) => <li key={index}>{text}</li>)}
          </div>
        </div>
        <div className='onlyprint'>
        <div className='columns'>
          <div className='MedicationResults'>
            <h4><b>On your day of surgery, please HOLD:</b></h4>
            {holdglp1 ? <div className="EmptyLine prepop">{holdglp1}</div> : null}
            {holdsglt2 ? <div className="EmptyLine prepop">{holdsglt2}</div> : null}
            {hold?.trim() ? <p>{showHoldNone ? hold.trim().replaceAll(/\n+/g,', ') : ''}</p> : printLines(8 - +!!holdglp1 - +!!holdsglt2)}
          </div>
          <div className='MedicationResults'>
            <h4><b>On your day of surgery, please TAKE:</b></h4>
            {take?.trim() ? <p>{take.trim().replaceAll(/\n+/g,', ')}</p> : printLines(8)}
          </div>
        </div>
        </div>
        {(flat["questionLocalAnesthesia"] === "False" && flat["question39"] === "True") && <div style={{marginTop: '1em'}}>***Please <b>BRING YOUR CPAP</b> machine to the hospital on the day of surgery ***</div>}
        </div>

        <div className={preadmission ? 'hide' : 'onlyprint'}>
        <h2><u>Fasting Rules for Adult Patients</u></h2>
        Please refrain from eating or drink for the following periods prior to your surgery time:
        <br />
        <i><ul>
          <li><b>2 hours clear liquids</b> (e.g. water, fruit juice without pulp, black coffee, sports drink)<br/><u>We, however, strongly encourage you to drink more fluids up to the deadline as this may lower your surgical and anesthesia risks</u></li>
          <li><b>6 hours light meal</b> (easily-digested carbohydrate, low protein, low fat - e.g. toast and a clear fluid, noodles in a clear broth)</li>
          <li><b>8+ hours heavy meal</b> (e.g. meat, fried or fatty foods)</li>
        </ul></i>
        <p style={{marginBottom:0, paddingBottom:0}}>
          <b>NOTE</b> it is preferable to <u>avoid eating food altogether after midnight</u> prior to surgery (does not include fluids), as sometimes operating room conditions could allow your surgery to happen earlier than scheduled.
        </p>
        </div>

        <PrintPageBreak className={'onlyprint'}/>
        <Complete onBack={() => {}} formValues={flat} showField={showField} onPrint={() => {}} result />
      </div>
    )
  }
}